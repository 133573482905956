import React, { useState } from "react"
import useCookie from "../../hooks/useCookie"
import Api from "../../utils/Api"

const CreatePassword = ({ hide, onClose, onSuccess, folderId, onSetPassword, onVerification }) => {
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const { setSessionFolder, sessionFolder } = useCookie()

    const savePassword = async () => {
        setLoading(true)
        if (!password) {
            setError('Password field requried!');
            setLoading(false)
            return;
        }

        try {
            const response = await Api.post(`/options/${folderId}?code=${sessionFolder().code}`, { password: password })
            if (response.data.success) {
                onSuccess('Password set successful!')
                setSessionFolder({ password })
                onSetPassword(password)
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            if (err.response.data.message === 'VERIFICATION INVALID') {
                onVerification(true, 'UPDATE_PASSWORD')
                onClose()
                return;
            }

            const errors = err?.response?.data?.message?.password[0]
            if (errors) {
                setError(errors)
                return;
            }
        }
    }

    return (
        <section className={`modal ${hide ? 'hide' : ''}`}>
            <div className="alert-popap password">
                <div className="modal-header">
                    <img onClick={!loading && onClose} src="./svg/close.svg" alt="close modal" />
                    <h2>Create password</h2>
                </div>
                <form onSubmit={(e) => e.preventDefault()} method="post" className="verification">
                    <article className="group-form">
                        <input disabled={loading} onChange={(e) => {setPassword(e.target.value); setError('')}} className={error ? 'error' : ''} type="password" name="password" id="password" placeholder="Write a password" />
                        <span>Write a password</span>
                        {error && <small className="form-invalid">{error}</small>}
                    </article>
                    <button id="UPDATE_PASSWORD" onClick={savePassword} className={`${loading ? 'disabled' : ''}`} type="button">Create password</button>
                </form>
            </div>
        </section>
    )
}

export default CreatePassword
