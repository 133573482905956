import { sumBy } from "lodash";
import { useEffect, useState } from "react";
import { Uploader } from "../services/Uploader";
import { bytesToSize } from "../utils/helper";
import useCookie from "./useCookie";

const useUpload = (id = null) => {
  const [isFinalized, setIsFinalized] = useState(false);
  const [progress, setProgress] = useState({});
  const { setSessionFolder, sessionFolder } = useCookie();

  let upload;

  const startUpload = async (files, onAbort) => {
    const folderEixsts = sessionFolder();

    const options = {
      file: files,
      folder: id || folderEixsts.folder_id,
    };

    upload = new Uploader(options);
    window.uploader = upload;

    let result;

    try {
      result = await upload.init();
    } catch (err) {
      onAbort();
      console.log("Error while initialize " + err.message);
      return;
    }

    if (!result) {
      console.log("Error while uploading");
      onAbort();
      return;
    }

    try {
      upload.upload();
    } catch (err) {
      console.log("Error Failed");
    }

    upload.checkIfFinalized((fianlzied) => {
      setIsFinalized(fianlzied === true);
      setSessionFolder({ isFinalized: true });
    });

    upload.onProgress(async (res) => {
      const values = Object.values(res);
      const totalSize = sumBy(values, "totalSize");
      const uploadedSize = sumBy(values, "uploadedSize");
      const percentage = Math.round((uploadedSize / totalSize) * 100);

      setProgress({
        percentage: Math.round(percentage * 100) / 100,
        totalSize: bytesToSize(totalSize * 1024),
        uploadedSize: bytesToSize(uploadedSize * 1024, true),
      });
    });
  };

  useEffect(() => {
    window.ononline = (event) => {
      window.uploader.resumeFaileds();
      console.log("Back Online");
    };
  }, []);

  const uploadAbort = () => {
    if (window.uploader) {
      window.uploader.abort();
    }
  };

  return {
    startUpload,
    progress,
    isFinalized,
    setProgress,
    setIsFinalized,
    uploadAbort,
  };
};

export default useUpload;
