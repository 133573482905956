import { useCookies } from "react-cookie";

const COOKIE_KEY = "a_transfer";

const useCookie = () => {
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_KEY]);

  const setSessionFolder = (data) => {
    const expiresDate = new Date();
    expiresDate.setDate(new Date().getDate() + 14);

    const storage = cookies.a_transfer ? cookies.a_transfer : {};

    let newSorage = data;
    if (storage) {
      newSorage = { ...storage, ...newSorage };
    }

    setCookie(COOKIE_KEY, JSON.stringify(newSorage), {
      path: "/",
      expires: expiresDate,
    });
  };

  const sessionFolder = () => {
    const result = cookies.a_transfer ? cookies.a_transfer : null;

    if (!result) {
      return {
        code: "",
        email: "",
        folder_id: "",
        password: "",
      };
    }

    return result;
  };

  return {
    setSessionFolder,
    sessionFolder,
    removeCookie,
  };
};

export default useCookie;
