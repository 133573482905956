import React from "react"
import { useEffect, useState } from "react"
import useCopyLink from "../hooks/useCopyLink"
import { useNavigate } from 'react-router-dom'
import useUpdateFolderName from "../hooks/useUpdateFolderName"
import Success from "./Modals/Success"
import useCookie from "../hooks/useCookie"

const GenerateLink = ({folderId, showOptions}) => {
    const [link, setLink] = useState('')
    const [successMsg, setSuccessMsg] = useState('')
    const { name, disabled, changeName } = useUpdateFolderName(setSuccessMsg)
    const { copy, copied } = useCopyLink(folderId)
    const { sessionFolder, setSessionFolder } = useCookie()

    const navigate = useNavigate()

    useEffect(() => {
        const appUrl = process.env.REACT_APP_URL

        setLink(`${appUrl}/${folderId || sessionFolder().folder_id}`)
        setSessionFolder({ isFinalized: true });
    }, [])

    return (
        <>
            <div className="upload-outer">
                <div className="upload-title" style={{justifyContent: 'flex-start'}}>
                    <span onClick={() => navigate('/')}><img src="./svg/arrow.svg" alt="" /></span>
                    <h1 style={{marginLeft: 25}} className="mrg-small">Generated link</h1>
                    <span style={{visibility: 'hidden'}}>2/2</span>
                    <div className="btn-group">
                        <h2 className="description">Your link has been generated successfully</h2>
                    </div>
                </div>

                {/* upload files step two */}
                <form method="post" className="generated">
                    <article className="group-form">
                        <div className="files-group overflow">
                            <input type="text" readOnly onFocus={(e) => e.target.select()} id="" value={link} />
                            <section className="input-wrapper">
                                <div className="form-group">
                                    <input type="text" disabled={disabled} value={name} onChange={e => changeName(e, sessionFolder().folder_id)} placeholder="Write a link title" />
                                    <img src="./svg/link.svg" alt="link" />
                                </div>
                            </section>
                        </div>
                    </article>
                    <div className="setting-btn">
                        <button className="full-btn" onClick={copy} type="button">
                            {copied ? (<img style={{width: '23px', marginTop: '4px'}} src="/svg/check.svg" />) : 'Copy link'}
                        </button> 
                        <button onClick={showOptions} className="circle" type="button">...</button>
                    </div>
                </form>
            </div>

            {successMsg && <Success message={successMsg} onClose={() => setSuccessMsg("")} />}
        </>
    )
}

export default GenerateLink
