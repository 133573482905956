import React, { useEffect } from "react";
import Slider from "../components/Slider";
import EmailVerification from "../components/Uploads/EmailVerification";
import { useNavigate, useLocation } from 'react-router-dom'
import { useState } from "react";
import FileUpload from "../components/Uploads/FileUpload";
import FileList from "../components/Uploads/FileList";
import useUpload from "../hooks/useUpload";
import ProgressBar from "../components/Uploads/ProgressBar";
import moment from "moment";
import Confirm from "../components/Modals/Confirm";
import GenerateLink from "../components/GeneratedLink";
import useUpdateFolder from "../hooks/useUpdateFolder";
import useUpdateFolderName from "../hooks/useUpdateFolderName";
import Api from "../utils/Api";
import SettingsMenu from "../components/Settings/SettingsMenu";
import Success from "../components/Modals/Success";
import useCopyLink from "../hooks/useCopyLink";
import { infoAlert } from "../utils/helper";
import useFileSizeLimit from "../hooks/useFileSizeLimit";
import useCookie from "../hooks/useCookie";

const Upload = ({ onUploading }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [verified, setVerified] = useState(false)
    const [fileUploading, setFileUploading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [confirmTitle, setConfirmTitle] = useState('')
    const [showFileConfirmation, setShowFileConfirmation] = useState(null)
    const [showAbortConfirmation, setShowAbortConfirmation] = useState(false)
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
    const [isEmptyLink, setIsEmptyLink] = useState(false)
    const [id, setId] = useState(null)
    const [showSettings, setShowSettings] = useState(false)
    const [successMsg, setSuccessMsg] = useState('')
    const [password, setPassword] = useState('')
    const { startUpload, progress, uploadAbort, isFinalized, setIsFinalized } = useUpload()
    const { sendToEmail, changeEmail, errors, disabled, email } = useUpdateFolder(id, setSuccessMsg)
    const { name, disabled: nameDisable, changeName, setFolderName, folderName } = useUpdateFolderName(setSuccessMsg)
    const { copy, copied } = useCopyLink()
    const { checkLimit } = useFileSizeLimit()
    const { setSessionFolder, sessionFolder, removeCookie } = useCookie()

    const preventBack = () => {
        window.history.pushState(null, "", window.location.pathname);
    }

    useEffect(() => {
        setFolderName(id)
    }, [id])

    const handleBack = () => {
        /* eslint-disable */
        if (confirm('Changes that you made may not be saved.')) {
            history.back()
            uploadAbort()
        } else {
            preventBack()
            window.addEventListener('popstate', handleBack);
            return;
        }
    }

    useEffect(() => {
        const type = new URLSearchParams(location.search).get("type");
        setIsEmptyLink(type === 'link')
        const folderEixsts = sessionFolder();
        setId(folderEixsts?.folder_id)
        setFolderName(folderEixsts?.folder_id)

        if (folderEixsts?.isFinalized || type === 'link') {
            createFolder(folderEixsts.folder_id, folderEixsts.email)
        }

        return () => {
            if (fileUploading && !isFinalized) {
                uploadAbort()
            }
        }
    }, []);

    useEffect(() => {
        if (fileUploading && !isFinalized) {
            onUploading(true)
            preventBack();
            window.addEventListener('popstate', handleBack);
        } else {
            onUploading(false)
            window.removeEventListener('popstate', handleBack)
        }

        return () => {
            window.removeEventListener('popstate', handleBack)
        }
    }, [fileUploading, isFinalized])

    const createFolder = async (id, email) => {
        if (!id) {
            setVerified(false)
            sessionStorage.removeItem('a-transfer')
            return;
        }

        try {
            const response = await Api.post(`/create?id=${id ?? ''}`, { email })

            if (response.data.success) {
                setVerified(true)
                setSessionFolder({folder_id: response.data.id, email: email, password: '', isFinalized: false })
                setFolderName(response.data.id)
                setId(response.data.id)
            }
        } catch (err) {
            if (err.response.data.message === 'CODE_NOT_FOUND') {
                setVerified(false)
                removeCookie('a_transfer')
                return;
            }

            infoAlert('Somthing went wrong');
        }
    }

    const onFilesChoosed = (e) => {
        e.preventDefault()
    }

    const onUpload = (e) => {
        e.preventDefault()
        startUpload(fileList, confirmUploaAbort)
        setFileUploading(true)
    }

    const onRemoveFileFromList = (index, name) => {
        setConfirmTitle(`Remove “${name}”`)
        setShowFileConfirmation(index)
    }

    const confirmFileRemove = () => {
        const newFiles = Array.from(fileList)
        newFiles.splice(showFileConfirmation, 1)

        setFileList(newFiles)
        setShowFileConfirmation(null)
    }

    const abortUpload = () => {
        setShowAbortConfirmation(true)
    }

    const confirmUploaAbort = () => {
        uploadAbort()
        setFileList([])
        setFileUploading(false)
        setShowAbortConfirmation(false)
        setIsFinalized(false)
    }

    const confirmDelete = async () => {
        try {
            await Api.delete(`/remove/${id}?code=${sessionFolder().code}`)
            setShowDeleteConfirm(false)
            navigate('/')
            localStorage.removeItem('a-transfer')
            setId(null)
        } catch (err) {
            infoAlert('Somthing went wrong')
        }
    }

    const onMainFileUpload = (e) => {
        e.preventDefault()
        if (checkLimit(Array.from(e.target.files))) {
            setFileList(e.target.files)
        }
    }

    const addFile = (e) => {
        e.preventDefault()
        if (checkLimit(Array.from(fileList), Array.from(e.target.files))) {
            setFileList([...fileList, ...e.target.files])
        }
    }

    const onShowOptions = (e) => {
        e.preventDefault(); 
        setShowSettings(!showSettings)
    }

    return (
        <>
            <main>
                <section className="upload-wrapper">
                    {verified && isEmptyLink ? (
                        <GenerateLink showOptions={onShowOptions} folderId={id} />
                    ) : (
                        <div className="upload-outer">
                            <div className="upload-title" style={{justifyContent: 'flex-start'}}>
                                {verified ? (
                                    <>
                                    <span onClick={() => navigate('/')}><img src="./svg/arrow.svg" alt="" /></span>
                                    <h1 style={{marginLeft: 25}}>Upload & transfer files</h1>
                                    <span style={{visibility: 'hidden'}}>2/2</span>

                                    <div className="btn-group">
                                        <button className="upload">Upload files</button>
                                        <button className="generate" onClick={() => {navigate('/upload?type=link'); setIsEmptyLink(true)}}>Generate link</button>
                                    </div>
                                </>
                                ) : <h1>Upload files</h1>}
                            </div>

                            {/* Email verification */}
                            {(!verified && !fileUploading) ? <EmailVerification onSuccess={(folder) => { setVerified(true); setId(folder) }} /> : null}
                            {/* upload form */}
                            {(verified && !fileList.length && !fileUploading) ? <FileUpload onContinue={onFilesChoosed} handleUpload={onMainFileUpload} /> : null}
                            {/* uploading procces */}
                            {(fileUploading && !isFinalized) ? <ProgressBar onAbort={abortUpload} count={fileList.length} progress={progress} /> : null}
                            {/* progress uploading files */}
                            {(fileList.length && !fileUploading) ? <FileList onStartUpload={onUpload} onRemove={onRemoveFileFromList} fileList={fileList} onAddFile={addFile} /> : null}

                            {/* upload files step two */}
                            {isFinalized ? (
                                <form method="post" className="files">
                                    <article className="group-form">
                                        <div className="files-group overflow">
                                            <div className="file-item">
                                                <img src="./svg/folder.svg" alt="pdf" />
                                                <div className="files-info">
                                                    <h2>{folderName ? folderName : id} <img className="green" src="./svg/success.svg" alt="" /></h2>
                                                    <span>{progress.totalSize} <i className="circle-small"><i className="circle-small">&#9679;</i></i> {moment().format('D MMM. YY(h:mm)')}</span>
                                                </div>
                                                <div className="stop-upload" onClick={() => {setShowDeleteConfirm(true); setConfirmTitle(`Remove “${folderName || id}”`)}}>
                                                    <img className="red" src="./svg/delete.svg" alt="stop upload"  />
                                                </div>
                                            </div>
                                            <section className="input-wrapper">
                                                <div className="form-group">
                                                    <input type="text" disabled={nameDisable} value={name} onChange={(e) => changeName(e, sessionFolder().folder_id)} placeholder="Write a link title" />
                                                    <img src="./svg/link.svg" alt="link" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" value={email} onChange={(e) => changeEmail(e)} placeholder="Recipient e-mail address" />
                                                    <img src="./svg/message.svg" alt="email" />
                                                    {errors?.sent_email ? <small className="error">{errors?.sent_email[0]}</small> : null}
                                                </div>
                                            </section>
                                        </div>
                                    </article>
                                    <div className="setting-btn">
                                        <button onClick={onShowOptions} class="circle">...</button> 
                                        <button onClick={copy} type="button">
                                            {copied ? (<img style={{width: '23px', marginTop: '4px'}} src="/svg/check.svg" />) : 'Copy link'}    
                                        </button> 
                                        <button onClick={sendToEmail} className={disabled ? `disabled` : null}>Send via e-mail</button>
                                    </div>
                                </form>
                            ) : null}
                        </div>
                    )}
                </section>

                {/* Upload confirm */}
                <Confirm
                    open={showFileConfirmation !== null}
                    title={confirmTitle}
                    text="Are you sure you want to remove this file ?"
                    onCancel={() => setShowFileConfirmation(null)}
                    onConfirm={confirmFileRemove}
                />
                {/* Abort Upload */}
                <Confirm
                    open={showAbortConfirmation}
                    title="Stop uploading"
                    text="Are you sure you want to stop uploading files?"
                    onCancel={() => setShowAbortConfirmation(false)}
                    onConfirm={confirmUploaAbort}
                />
                {/* Delete folder confirm */}
                <Confirm
                    open={showDeleteConfirm}
                    title={confirmTitle}
                    text="Are you sure you want to remove this file ?"
                    onCancel={() => setShowDeleteConfirm(false)}
                    onConfirm={confirmDelete}
                />

                <Slider />
            </main>

            {successMsg && <Success message={successMsg} onClose={() => setSuccessMsg("")} />}
            <SettingsMenu 
                hasPassword={password} 
                onHasPassword={(pass) => setPassword(pass)} 
                show={showSettings} 
                setShow={setShowSettings} folderId={id} 
                removeDownloadOnly={verified && isEmptyLink}
            />
        </>
    )
}

export default Upload
