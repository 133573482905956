import React, { useEffect, useState } from "react";
import useCookie from "../../hooks/useCookie";
import Api from "../../utils/Api";
import { infoAlert } from "../../utils/helper";

const ExpiredDates = ({ hide, folderId, onSuccess, onHide }) => {
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('')
    const { sessionFolder } = useCookie()

    const fetchData = async () => {
        const response = await Api.get(`/folder/${sessionFolder().folder_id}?password=${sessionFolder().password || ''}`)

        setValue(response.data.expiredKey)
    }

    useEffect(() => {
        if (!hide) {
            fetchData()
        }
    }, [hide])

    const setExpiredDate = async (e) => {
        setValue(e.target.value)

        try {
            setLoading(true)
            const response = await Api.post(`/options/${folderId}?password=${sessionFolder().password || ''}&code=${sessionFolder().code}`, { expiredAt: e.target.value })
            if (response.data.success) {
                onSuccess('Expired date changed successful!')
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            infoAlert('Server error!' + err.message);
        }
    }

    return (
        <section className={`modal ${hide ? 'hide' : ''}`}>
            <div className="alert-popap dates">
                <div className="modal-header">
                    <img onClick={() => !loading && onHide()} src="./svg/close.svg" alt="close modal" />
                    <h2>Expire dates</h2>
                </div>
                <div className="date">
                    <input onChange={setExpiredDate} disabled={loading} type="radio" checked={value === '1_week'} value="1_week" name="date" id="week" />
                    <label htmlFor="week">One Week</label>
                </div>
                <div className="date">
                    <input onChange={setExpiredDate} disabled={loading} type="radio" checked={value === '1_month'} name="date" value="1_month" id="month" />
                    <label htmlFor="month">One Month</label>
                </div>
                <div className="date">
                    <input onChange={setExpiredDate} disabled={loading} type="radio" checked={value === '3_month'} name="date" value="3_month" id="three" />
                    <label htmlFor="three">Three Month</label>
                </div>
            </div>
        </section>
    )
}

export default ExpiredDates
