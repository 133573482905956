import { S3Client } from "@aws-sdk/client-s3";
const REGION = process.env.REACT_APP_AWS_REGION;

const s3Client = new S3Client({
  apiVersion: "2006-03-01",
  region: REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_S3_KEY_ID,
    secretAccessKey: process.env.REACT_APP_S3_ACCESS_KEY,
  },
});
export { s3Client };
