import { infoAlert, sizeMB } from "../utils/helper";

const sum = (acc, item) => (acc += item.size);

const useFileSizeLimit = () => {
  const checkLimit = (files, existingFiles = [], uploadedFiles = []) => {
    const sizes = files.reduce(sum, 0);
    const existingSizes = existingFiles.reduce(sum, 0);
    const uploadedSizes = uploadedFiles.reduce(sum, 0);
    const totalSize = sizeMB(sizes + existingSizes + uploadedSizes);

    const limit = process.env.REACT_APP_SIZE_LIMIT_GB || 20;
    const limitMB = limit * 1024;

    if (totalSize > limitMB) {
      infoAlert(`Maximum allowed upload size is ${limit}GB`);
      return false;
    }

    return true;
  };

  return {
    checkLimit,
  };
};

export default useFileSizeLimit;
