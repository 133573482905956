import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navabr";
import Download from "./pages/Download";
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
import Upload from "./pages/Upload";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "G-QNWRQM427F",
};

TagManager.initialize(tagManagerArgs);

export default function App() {
  const [uploading, setUploading] = useState(false);

  return (
    <div>
      <Navbar uploading={uploading} />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/upload"
          element={<Upload onUploading={(action) => setUploading(action)} />}
        />
        <Route path="/:id" element={<Download />} />
        <Route path="/not-found" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}
