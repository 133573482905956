import React from "react";

const PageNotFound = () => {
    return (
        <main style={{width: '100%', height: 'calc(100vh - 104px)', }}>
            <h2 style={{margin: 'auto'}}>404 | Page not found</h2>
        </main>
    )
}

export default PageNotFound
