import React from "react";
import { useState } from "react";

const FileUpload = ({ handleUpload, onContinue, hideContinue }) => {
    const [disabled, setDisabled] = useState(true)

    const onUpload = (e) => {
        setDisabled(false)
        handleUpload(e)
    }

    return (
        <form method="post" className="upload">
            <article className="group-form">
                <label htmlFor="inputTag">
                    <img src="./svg/upload.svg" alt="upload files" />
                    Upload file
                    <input onChange={onUpload} multiple id="inputTag" type="file"/>
                </label>
            </article>
            {!hideContinue && <button style={{visibility: 'hidden'}} onClick={onContinue} className={`${disabled ? 'disabled' : null}`}>Continue</button>}
        </form>
    )
}

export default FileUpload
