import { useEffect, useState } from "react";
import useCookie from "./useCookie";

const useCopyLink = () => {
  const [copied, setCopied] = useState(false);
  const { setSessionFolder, sessionFolder } = useCookie();

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);

      setSessionFolder({ isFinalized: true });
    }
  }, [copied]);

  const copy = () => {
    setCopied(true);
    const appUrl = process.env.REACT_APP_URL;
    const id = sessionFolder().folder_id;

    navigator.clipboard.writeText(`${appUrl}/${id}`);
  };

  return {
    copy,
    copied,
  };
};

export default useCopyLink;
