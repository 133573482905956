import { debounce } from "lodash";
import { useCallback, useState } from "react";
import Api from "../utils/Api";
import { infoAlert } from "../utils/helper";

const useUpdateFolderName = (setSuccessMsg) => {
  const [disabled, setDisabled] = useState(false);
  const [folderName, setFolderName] = useState();
  const [name, setName] = useState("");

  const changeName = (e, folderId) => {
    setName(e.target.value);
    if (e.target.value.length >= 2) {
      debounceSave(e.target.value, folderId);
    }
  };

  const saveName = async (name, folderId) => {
    setDisabled(true);

    try {
      await Api.post("/update/" + folderId, { name });
      setDisabled(false);
      setFolderName(name);

      setSuccessMsg("Link name successful updated!");
    } catch (err) {
      setDisabled(false);
      infoAlert("Somthing went wrong, try again");
    }
  };

  const debounceSave = useCallback(
    debounce(saveName, 1500, { trailing: true }),
    []
  );

  return {
    name,
    disabled,
    changeName,
    setFolderName,
    folderName,
  };
};

export default useUpdateFolderName;
