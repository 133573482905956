import React from "react";
import Slider from "../components/Slider";
import { useNavigate } from 'react-router-dom'

const Home = () => {
    const navigate = useNavigate()

    return (
        <main>
            <section className="upload-wrapper mobile-fixed">
                <div className="upload-outer full-height">
                    <div className="upload-title">
                        <h1>Upload & transfer files</h1>
                        <span></span>
                    </div>
                    <div className="upload-card" onClick={() => navigate('/upload')}>
                        <img className="white" src="./svg/upload-white.svg" alt="upload files" />
                        <img className="blue" src="./svg/upload-blue.svg" alt="upload files" />
                        <h2>Upload files</h2>
                        <p>Choose this if you want to send some files to someone</p>
                    </div>

                    <div className="upload-card" onClick={() => navigate('/upload?type=link')}>
                        <img className="white" src="./svg/link-white.svg" alt="generate link" />
                        <img className="blue" src="./svg/link-blue.svg" alt="generate link" />
                        <h2>Generate empty link</h2>
                        <p>Choose this one if someone has to send some files to you</p>
                    </div>
                </div>
            </section>

            <Slider />
        </main>
    )
}

export default Home
