import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useEffect } from "react";

const slideItems = [
    './svg/sliders/slider_img_1.jpg',
    './svg/sliders/slider_img_2.jpg',
    './svg/sliders/slider_img_3.jpg',
    './svg/sliders/slider_img_4.jpg',
    './svg/sliders/slider_img_5.jpg',
    './svg/sliders/slider_img_6.jpg',
    './svg/sliders/slider_img_7.jpg',
    './svg/sliders/slider_img_8.jpg',
    './svg/sliders/slider_img_9.jpg',
    './svg/sliders/slider_img_10.jpg',
    './svg/sliders/slider_img_11.jpg',
    './svg/sliders/slider_img_12.jpg',
]

const Slider = () => {
    const [shuffledSlideItems, setShuffledSlideItems] = useState(slideItems)

    useEffect(() => {
        setShuffledSlideItems(slideItems.sort((a, b) => 0.5 - Math.random()))
    }, [])

    return (
        <section className="slider-wrapper">
            <Swiper
                modules={[Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                loop={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                pagination={{
			        clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '"><svg height="9" width="9"><circle class="circle" cx="4.5" cy="4.5" r="3" stroke="#fff" stroke-width="2" fill-opacity="0" /></svg></span>';
                    },
                }}
            >
                {
                    shuffledSlideItems.map((slide, index) => <SwiperSlide key={index}><img key={index} src={slide} alt={`aptos transfer slide ${index}`} /></SwiperSlide>)
                }
            </Swiper>
        </section>
    )
}

export default Slider
