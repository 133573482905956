import React, { useEffect, useState, useRef } from "react";
import useCookie from "../../hooks/useCookie";
import Api from "../../utils/Api";
import Confirm from "../Modals/Confirm";
import CreatePassword from "../Modals/CreatePassword";
import Success from "../Modals/Success";
import ExpiredDates from "./ExpiredDates";

const SettingsMenu = ({ 
        show, 
        setShow, 
        folderId, 
        download, 
        hasPassword, 
        onHasPassword, 
        onVerification, 
        downloaDonlyMode, 
        SetIsDownloadOnly, 
        removeDownloadOnly,
        requestVerification
    }) => {
    const [showExpired, setShowExpired] = useState(false)
    const [showSetPassword, setShowSetPassword] = useState(false)
    const [showSuccess, setShowSuccess] = useState('')
    const [isDownloadOnly, setIsDownloadOnly] = useState(false)
    const [loadingDownloadOnly, setLoadingDownloadOnly] = useState(false)
    const [showConfirm, setShowConfirm] = useState({text: '', type: 'remove'})
    const [disabled, setDisabled] = useState(false)
    const ref = useRef(null);
    const { setSessionFolder, sessionFolder } = useCookie()

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShow(false)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref])

    useEffect(() => {
        if (document.getElementById(requestVerification)) {
            document.getElementById(requestVerification).setAttribute('data', 'unconfirmed')
            document.getElementById(requestVerification).click()
            document.getElementById(requestVerification).removeAttribute('data')
        }
    }, [requestVerification])

    useEffect(() => {
        setIsDownloadOnly(downloaDonlyMode)
    }, [])

    const setExpired = () => {
        setShowExpired(true);
        setShow(false)
    }

    const handleExpiredSuccess = (msg) => {
        setShowExpired(false);
        setShowSuccess(msg);
    }

    const onRemove = (event) => {
        if (event.target.getAttribute('data') === 'unconfirmed') {
            onSuccessConfirm(true)
            setShow(false)
            return;
        }

        setShowConfirm({text: 'Are you sure to delete this link?', type: 'remove'});
        setShow(false)
    }

    const onSuccessConfirm = async (remove = false) => {
        if (showConfirm.type === 'remove' || remove) {
            setDisabled(true)
            try {
                const response = await Api.delete(`/remove/${folderId}?password=${hasPassword}&code=${sessionFolder().code}`)
                if (response.data.success) {
                    sessionStorage.removeItem("a-transfer");
                    setDisabled(false)
                    setShow(false)
                    setShowSuccess('Link removed successful!')
                    setShowConfirm({text: ""})
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 3000);
                }
            } catch (err) {
                setDisabled(false)
                if (err.response.data.error === 'VERIFICATION INVALID') {
                    setShowConfirm({text: ""})
                    onVerification(true, 'REMOVE_LINK')
                }
            }
        } else if (showConfirm.type === 'password_remove') {
            removePassword()
        }
    }

    const removePassword = async () => {
        setDisabled(true)
        try {
            const response = await Api.post(`/options/${folderId}?password=${hasPassword}&code=${sessionFolder().code}`, { remove_password: true })
            setDisabled(false)
            if (response.data.success) {
                setShowSuccess('Password removed successful!')
                setShowConfirm({text: '', type: ''})
                setSessionFolder({password: ''})
                onHasPassword(false)
                setShow(false)
            }
        } catch (err) {
            setDisabled(false)
            if (err.response.data.message === 'VERIFICATION INVALID') {
                setShowConfirm({text: '', type: ''})
                onVerification(true, "REMOVE_PASSWORD");
            }
        }
    }

    const createPassword = async (event) => {
        if (hasPassword) {
            if (event.target.getAttribute('data') === 'unconfirmed') {
                removePassword()
                return;
            }

            setShowConfirm({text: 'Remove password?', type: 'password_remove'})
            return;
        }
        setShowSetPassword(true)
        setShow(false)
    }

    const handleSuccessSetPassword = (msg) => {
        setShowSetPassword(false);
        setShowSuccess(msg);
    }

    const onDownloadOnly = async () => {
        if (loadingDownloadOnly) {
            return;
        }

        setShow(false)
        setLoadingDownloadOnly(true)

        try {
            const response = await Api.post(`/options/${folderId}?password=${hasPassword || ''}&code=${sessionFolder().code}`, { isDownloadOnly: !isDownloadOnly ? 1 : 0 })
            if (response.data.success) {
                setLoadingDownloadOnly(false)
                localStorage.setItem('a-transfer-download-only', !isDownloadOnly)
                setShowSuccess(!isDownloadOnly ? 'Download only mode was activated' : 'Download only mode was removed')
                setIsDownloadOnly(!isDownloadOnly)
                SetIsDownloadOnly(!isDownloadOnly)
            }
        } catch (err) {
            setLoadingDownloadOnly(false)
            if (err.response.data.message === 'VERIFICATION INVALID') {
                onVerification(true, 'DOWNLOAD_ONLY_VERIFFICATION')
            }
        }
    }

    const styles = removeDownloadOnly ? {
        height: '83px',
        paddingBottom: 0,
        paddingTop: '15px'
    } : {}

    return (
        <>
            <section className={`modal ${!show ? 'hide' : ''}`}>
                <div className={`alert-popap settings ${!download ? 'files-settings' : ''}`} style={styles} ref={ref}>
                    <div className="modal-header desctop-hidden">
                        <img onClick={() => setShow(false)} src="./svg/close.svg" alt="close modal" />
                        <h2>Settings</h2>
                    </div>
                    {
                        download ? (
                            <div className="setting">
                                <a id="REMOVE_LINK" onClick={onRemove} href="#">Remove this link</a>
                                <img src="./svg/delete.svg" alt="" />
                            </div>
                        ) : (
                            <div className="setting">
                                <a onClick={setExpired} href="#">Set expire time</a>
                                <img src="./svg/calendar.svg" alt="" />
                            </div>
                        )
                    }
                    <div className="setting">
                        <a id={`${hasPassword ? 'REMOVE_PASSWORD' : ''}`} onClick={createPassword} href="#">{hasPassword ? 'Remove' : 'Create'} password</a>
                        <img src="./svg/password.svg" alt="" />
                    </div>
                    {!removeDownloadOnly ? (
                        <div className="setting">
                            <a id="DOWNLOAD_ONLY_VERIFFICATION" onClick={onDownloadOnly} href="#">{!isDownloadOnly ? `Choose “download only” mode` : 'Remove “download only” mode'}</a>
                            <img src="./svg/look.svg" alt="" />
                        </div>
                    ) : null}
                </div>
            </section>

            <ExpiredDates hide={!showExpired} folderId={folderId} onSuccess={handleExpiredSuccess} onHide={() => setShowExpired(false)} />
            <CreatePassword hide={!showSetPassword} onVerification={onVerification} onClose={() => setShowSetPassword(false)} folderId={folderId} onSuccess={handleSuccessSetPassword} onSetPassword={onHasPassword} />

            {showSuccess && <Success message={showSuccess} onClose={() => setShowSuccess(false)} />}
            <Confirm open={!!showConfirm.text} text={showConfirm.text} disabled={disabled} onConfirm={() => onSuccessConfirm(false)} onCancel={() => setShowConfirm({text: ""})} />
        </>
    )
}

export default SettingsMenu
