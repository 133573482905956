import React from "react";

const Success = ({ title, message, btn, onClose }) => (
    <section className="modal">
        <div className="alert-popap success">
            <div className="modal-header">
                <img onClick={onClose} src="./svg/close.svg" alt="close modal" />
                {title && <h2>Remove generated link</h2>}
            </div>
            <form method="post" className="verification">
                <div className="circle-success">
                    <img src="./svg/success.svg" alt="" />
                </div>
                {message && <h2 className="success">{message}</h2>}
                {btn && <button className="" type="submit">Go to main page</button>}
            </form>
        </div>
    </section>
)

export default Success;
