import { useEffect, useState } from "react"
import useCookie from "../hooks/useCookie"
import Api from "../utils/Api"
import { infoAlert } from "../utils/helper"

const VerifyForm = ({email, folderId, verification, onSuccess}) => {
    const [code, setCode] = useState({0: '', 1: '', 2: '', 3: ''})
    const [error, setError] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [counter, setCounter] = useState(120);
    const [seconds, setSeconds] = useState(0)
    const { setSessionFolder, removeCookie } = useCookie()

    useEffect(() => {
        if (verification) {
            if (counter > 0) {
                setTimeout(() => {
                    setCounter(counter - 1)
                    counter > 60 ? setSeconds(counter - 60 - 1) : setSeconds(counter - 1)
                }, 1000)
            } else {
                setCode({0: '', 1: '', 2: '', 3: ''})
            }
        }
    }, [counter, verification]);

    const onKeyDown = (event) => {
        setDisabled(false)
        setError('')

        const form = event.target.form;
        const index = [...form].indexOf(event.target);

        if (event.key.toLowerCase() === "backspace") {
            setCode({...code, [index]: ''})
            if (index === 0) {
                setDisabled(true)
            }

            if (index !== 0) {
                form.elements[index - 1].focus();
                setDisabled(true)
            } else {
                form.elements[index].focus();
            }
            event.preventDefault();
        }
    }

    const setCodeValue = (event) => {
        setError('')
        const form = event.target.form;
        const index = [...form].indexOf(event.target);

        if (!event.target.value.match(/[0-9]/g)) {
            form.elements[index].focus();
            return;
        }

        if (code[index] && code[index] >= 0) {
            form.elements[index + 1].focus();
            return;
        }

        const newCode = {...code, [index]: event.target.value};
        setCode(newCode)

        if (Object.values(newCode).filter(it => it).length === 4) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }

        if (index !== 3) {
            form.elements[index + 1].focus();
        }
    }

    const verifyMail = async (event) => {
        event.preventDefault()
        setDisabled(true)

        try {
            const response = await Api.post(`/verify/${folderId}`, { email, code: Object.values(code).join('') })

            if (response.data.success) {
                removeCookie('a_transfer')
                setSessionFolder({folder_id: folderId, email: email, code: Object.values(code).join('') })
                onSuccess(folderId)
            }
        } catch (err) {
            setDisabled(false)
            const errors = err?.response?.data?.email
            if (errors) {
                setError(errors)
                return;
            }

            setError('Error')
        }
    }

    const resendCode = async (e) => {
        e.preventDefault()
        setError('')

        try {
            await Api.post('/resend/' + folderId, { email })
            setCounter(120)
            setCode({0: '', 1: '', 2: '', 3: ''})
        } catch (err) {
            infoAlert('Somthing went wrong')
        }
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');;
    }

    const handlePaste = (e) => {
        const code = e.clipboardData.getData('Text').split("")
        setCode(code)
    }

    return (
        <form method="post" className="code">
            <article>
                <label htmlFor="code">Write a code, you recieved on e-mail</label>
                <div className="group-input">
                    {/* // input add/remove class error or valid */}
                    <input type="tel" pattern="[0-9]*" onPaste={handlePaste} onKeyDown={onKeyDown} onChange={setCodeValue} value={code[0]} disabled={counter === 0 ? true : false} className={error ? 'error' : ''} tabIndex="1" />
                    <input type="tel" pattern="[0-9]*" onPaste={handlePaste} onKeyDown={onKeyDown} onChange={setCodeValue} value={code[1]} disabled={counter === 0 ? true : false} className={error ? 'error' : ''} tabIndex="2" />
                    <input type="tel" pattern="[0-9]*" onPaste={handlePaste} onKeyDown={onKeyDown} onChange={setCodeValue} value={code[2]} disabled={counter === 0 ? true : false} className={error ? 'error' : ''} tabIndex="3" />
                    <input type="tel" pattern="[0-9]*" onPaste={handlePaste} onKeyDown={onKeyDown} onChange={setCodeValue} value={code[3]} disabled={counter === 0 ? true : false} className={error ? 'error' : ''} tabIndex="4" />
                </div>
                {error && <p>Code is incorrect</p>}
                <span>{`${Math.floor(counter / 60)}:${padTo2Digits(seconds)}`}</span>
                <a onClick={resendCode} className={counter !== 0 ? 'disabled' : null} href="/">Resend code</a>
            </article>
            <button onClick={verifyMail} className={`${disabled || counter === 0 ? 'disabled' : null}`}>Submit</button>
        </form>
    )
}

export default VerifyForm
