import {
  CreateMultipartUploadCommand,
  UploadPartCommand,
  CompleteMultipartUploadCommand,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import axios from "axios";
import { s3Client } from "./S3Client";
import { orderBy, sumBy } from "lodash";
import Api from "../utils/Api";
import Resizer from "react-image-file-resizer";

export class Uploader {
  constructor(options) {
    this.chunkSize = options.chunkSize || 1024 * 1024 * 5;
    this.folder = options.folder || "";
    this.file = options.file;
    this.uploadId = [];
    this.Bucket = process.env.REACT_APP_S3_BUCKET;
    this.uploadedParts = {};
    this.finishedParts = {};
    this.chunks = {};
    this.cancelTokenSource = axios.CancelToken.source();
    this.progress = {};
    this.uploadedFiles = 0;
    this.uploadIdWithFiles = [];
    this.progressParts = {};
    this.failedChunks = [];
    this.failedFinalzies = [];
    this.onProgressFn = () => {};
    this.setIsFinalized = () => {};
  }

  async resumeFaileds() {
    await Promise.all(this.failedChunks);

    this.failedFinalzies.forEach((index) => {
      this.finalize(index);
    });
  }

  round(num, rounded = 10) {
    const roundDitits = rounded || 10;

    return Math.round(num / roundDitits) * roundDitits;
  }

  async upload() {
    const allApiCals = [];
    Array.from(this.file).forEach(async (file, index) => {
      let uploadUrls = [];
      let i;
      const partItems = {};

      for (i = 1; i <= this.chunks[index]; i++) {
        partItems[i] = 0;
        const sentSize = (i - 1) * this.chunkSize;
        const body = file.slice(sentSize, sentSize + this.chunkSize);
        const url = await this.getPartsUploadUrl(body, i, file, index);
        uploadUrls[i] = { url, body };
      }

      this.progressParts[index] = partItems;

      uploadUrls.forEach(async ({ url, body }, partNumber) => {
        allApiCals.push(this.chunkUpload(url, partNumber, body, index));
      });

      uploadUrls = [];
    });

    await Promise.all(allApiCals);
  }

  async onProgressHandler(event, partNumber, index) {
    const loaded = this.round(event.loaded / 1024);
    this.progressParts[index][partNumber] = loaded;

    const totalLoaded = this.round(
      sumBy(Object.values(this.progressParts[index]))
    );

    this.progress[index].uploadedSize = totalLoaded;
    this.progress[index].percentage = this.round(
      (totalLoaded / this.progress[index].totalSize) * 100
    );

    this.onProgressFn(this.progress);
  }

  checkIfFinalized(finalize) {
    this.setIsFinalized = finalize;
    return this;
  }

  onProgress(onProgress) {
    this.onProgressFn = onProgress;
    return this;
  }

  async initializeToApi(files) {
    try {
      await Api.post("/initialize/" + this.folder, { files });

      return true;
    } catch (err) {
      return false;
    }
  }

  async init() {
    return new Promise(async (resolve, reject) => {
      let totalFileSize = Array.from(this.file).reduce((carry, item) => {
        carry += item.size;

        return carry;
      }, 0);

      totalFileSize = this.round(totalFileSize / 1024 / 1024, 10);

      if (totalFileSize >= 1000) {
        this.chunkSize = 1024 * 1024 * 50;
      } else if (totalFileSize >= 500) {
        this.chunkSize = 1024 * 1024 * 15;
      }

      const apiCalls = [];
      Array.from(this.file).forEach(async (file, index) => {
        this.progress[index] = {
          totalSize: 0,
          uploadedSize: 0,
          percentage: 0,
        };
        this.chunks[index] = Math.ceil(file.size / this.chunkSize);
        this.uploadedParts[index] = [];
        this.finishedParts[index] = 0;

        this.progress[index].totalSize = this.round(file.size / 1024);

        apiCalls.push(this.initializeUpload(file, index));
      });

      const results = await Promise.all(apiCalls);
      this.uploadId = results.map((item) => item.uploadId);

      resolve(true);
    });
  }

  async initializeUpload(file, index) {
    const multipartParams = {
      Bucket: this.Bucket,
      Key: `${this.folder}/${file.name}`,
    };
    try {
      const signedUrl = await getSignedUrl(
        s3Client,
        new CreateMultipartUploadCommand(multipartParams)
      );

      const result = await axios.post(signedUrl);
      const xmlDoc = this.convertXmlToJson(result.data);

      this.uploadIdWithFiles[index] =
        xmlDoc.InitiateMultipartUploadResult.UploadId;

      return {
        uploadId: xmlDoc.InitiateMultipartUploadResult.UploadId,
        fileKey: `${this.folder}/${file.name}`,
      };
    } catch (err) {
      throw new Error(err);
    }
  }

  async getPartsUploadUrl(body, partNumber, file, index) {
    const uploadParams = {
      Bucket: this.Bucket,
      Key: `${this.folder}/${file.name}`,
      Body: body,
      PartNumber: partNumber,
      UploadId: this.uploadId[index],
    };

    try {
      const signedUrl = await getSignedUrl(
        s3Client,
        new UploadPartCommand(uploadParams),
        {
          expiresIn: 60 * 60,
        }
      );
      return signedUrl;
    } catch (err) {
      throw new Error(err);
    }
  }

  async abort() {
    Array.from(this.file).forEach(async (file, index) => {
      this.cancelTokenSource.cancel();
      const fileKey = `${this.folder}/${file.name}`;

      const data = {
        fileKey,
        uploadIds: this.uploadId[index],
      };

      await Api.post("/cancel", data);
    });
  }

  async chunkUpload(url, partNumber, body, index) {
    axios
      .request({
        method: "PUT",
        url,
        data: body,
        headers: {
          "Content-Type": body.type,
        },
        cancelToken: this.cancelTokenSource.token,
        onUploadProgress: (progress) =>
          this.onProgressHandler(progress, partNumber, index),
      })
      .then(async (response) => {
        this.finishedParts[index] += 1;
        this.uploadedParts[index].push({
          ETag: response.headers.etag.replaceAll('"', ""),
          PartNumber: partNumber,
        });

        if (this.finishedParts[index] === this.chunks[index]) {
          setTimeout(() => {
            this.finalize(index);
          }, 500);
        }
      })
      .catch((err) => {
        const isNetworkError = err.message === "Network Error";

        if (isNetworkError) {
          this.failedChunks.push(
            this.chunkUpload(url, partNumber, body, index)
          );
        }
      });
  }

  async resizeFile(file) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        110,
        110,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  }

  async finalize(index) {
    try {
      const completeParams = {
        Bucket: this.Bucket,
        Key: `${this.folder}/${this.file[index].name}`,
        MultipartUpload: {
          Parts: orderBy(this.uploadedParts[index], ["PartNumber"]),
        },
        UploadId: this.uploadId[index],
      };

      const result = await s3Client.send(
        new CompleteMultipartUploadCommand(completeParams)
      );

      this.uploadedFiles += 1;

      if (this.uploadedFiles === this.file.length) {
        const files = Array.from(this.file).map((item, index) => ({
          folder: this.folder,
          name: item.name,
          size: item.size,
          mime_type: item.type,
          lastModified: item.lastModified,
          uploadId: this.uploadIdWithFiles[index],
        }));

        const response = await Api.post("/finalize/" + this.folder, files);
        for (let i = 0; i < Array.from(this.file).length; i++) {
          if (!this.file[i]["type"].includes("image/")) {
            continue;
          }

          const thumb = await this.resizeFile(this.file[i]);

          await Api.post(`/save-thumb/${this.folder}`, {
            file: thumb,
            name: this.file[i]["name"],
          });
        }

        if (response.data.success) {
          this.setIsFinalized(true);
        }
      }

      return result;
    } catch (err) {
      this.failedFinalzies.push(index);

      if (this.failedFinalzies.length) {
        this.failedFinalzies.forEach((item) => {
          this.finalize(item);
        });
      }

      throw new Error(err);
    }
  }

  convertXmlToJson(xmlString) {
    const jsonData = {};
    for (const result of xmlString.matchAll(
      /(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm
    )) {
      const key = result[1] || result[3];
      const value = result[2] && this.convertXmlToJson(result[2]); //recusrion
      jsonData[key] =
        (value && Object.keys(value).length ? value : result[2]) || null;
    }
    return jsonData;
  }
}
