import React, { useState } from "react"

const EnterPassword = ({ settedPassword, loading, error, setError }) => {
    const [password, setPassword] = useState('')

    const savePassword = async (e) => {
        e.preventDefault()
        settedPassword(password)
    }

    return (
        <form onSubmit={(e) => e.preventDefault()} method="post" className="verification">
            <article className="group-form">
                <label for="">Please, enter the password to download files</label>
                <input className={error ? 'error' : ''} onChange={(e) => {setPassword(e.target.value); setError('')}} type="password" name="password" id="password" placeholder="Write a password" />
                <span className="write-pass">Write a password</span>
                {error ? <p>{error}</p> : '' }
            </article>
            <button onClick={savePassword} className={`${loading ? 'disabled' : ''}`} type="button">Done</button> 
        </form>
    )
}

export default EnterPassword
