import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useCookie from "../../hooks/useCookie";
import Api from "../../utils/Api";
import { infoAlert } from "../../utils/helper";
import VerifyForm from "../VerifyForm";

const EmailVerification = ({ onSuccess }) => {
    const [verification, setVerification] = useState(null)
    const [disabled, setDisabled] = useState(true)
    const [submitted, makeSubmited] = useState(false)
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [folderId, setFolderId] = useState(null)
    const { sessionFolder } = useCookie()

    useEffect(() => {
        setDisabled(true)
    }, [verification])

    useEffect(() => {
        const folderEixsts = sessionFolder()

        if (folderEixsts?.folder_id) {
            setEmail(folderEixsts.email)
            onSuccess()
        }
    }, [])

    const setValue = (event) => {
        setDisabled(false)
        setEmail(event.target.value)
        setError('')
    }

    const sendVerficiationMail = async (event) => {
        event.preventDefault()
        setDisabled(true)
        makeSubmited(true)

        try {
            const response = await Api.post('/create', { email })

            if (response.data.success) {
                setVerification(true)
                setFolderId(response.data.id)
            }
        } catch (err) {
            setDisabled(false)
            makeSubmited(false)
            const errors = err?.response?.data?.message
            if (errors) {
                setError(errors.email[0])
                return
            }

            infoAlert('Somthing went wrong');
        }

        makeSubmited(false)
        setDisabled(true)
    }

    return (
        <>
            {/* email verification */}
            {!verification && <form method="post" className="verification">
                <article className="group-form">
                    <label htmlFor="email">Write your e-mail for verification and recieve a code</label>
                    <input type="email" disabled={submitted} className={`${error ? 'error' : null}`} onChange={setValue} name="email" id="email" placeholder="Write e-mail" />
                    <span>Write e-mail</span>
                    {error && <small className="form-invalid">{error}</small>}
                </article>
                <button onClick={sendVerficiationMail} className={`${disabled ? 'disabled' : null}`} type="submit">Send a code</button> 
            </form>}

            {verification && (
                <VerifyForm email={email} folderId={folderId} verification={verification} onSuccess={onSuccess} />
            )}
        </>
    )
}

export default EmailVerification
