import React, { useEffect, useState } from "react";

const ProgressBar = ({ count, onAbort, progress }) => {
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false)

    useEffect(() => {
        setShouldBlockNavigation(true)

        return () => {
            setShouldBlockNavigation(false)
        }
    }, [])

    useEffect(() => {
        if (shouldBlockNavigation) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }

        return () => {
            window.onbeforeunload = undefined
        }
    })

    return (
        <>
            <form method="post" className="uploading">
                <article className="group-form">
                    <div className="files-group">
                        <img src="./svg/folder.svg" alt="folder" />
                        <div className="files-info">
                            <h2>Uploading {count} files...</h2>
                            {!progress.totalSize ? <span>Prepare to upload...</span> : <span>{progress.uploadedSize} OF / <i className="circle-small">&#9679;</i> {progress.totalSize} ({progress.percentage}%)</span>}
                        </div>
                        <div className="stop-upload" onClick={onAbort}>
                            <img src="./svg/close.svg" alt="stop upload" />
                        </div>
                    </div>

                    <div className="progress">
                        <span style={{width: `${progress.percentage || 0}%`}}></span>
                    </div>
                </article>
                <button onClick={onAbort} type="button">Stop uploading</button> 
            </form>
        </>
    )
}

export default ProgressBar
