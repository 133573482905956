export const imageSrc = (fileObject, thumb = "") => {
  if (!fileObject.size) {
    return "./svg/unknown.svg";
  }

  if (thumb) {
    return thumb;
  }

  if (fileObject.type.includes("image/") && !thumb) {
    try {
      return URL.createObjectURL(fileObject);
    } catch {
      return "";
    }
  }

  if (
    ["excel", "sheet", "csv"].find((item) => fileObject.type.includes(item))
  ) {
    return "./svg/excel.svg";
  }

  if (["word"].find((item) => fileObject.type.includes(item))) {
    return "./svg/word.svg";
  }

  if (["zip", "archive"].find((item) => fileObject.type.includes(item))) {
    return "./svg/zip.svg";
  }

  if (["pdf"].find((item) => fileObject.type.includes(item))) {
    return "./svg/pdf.svg";
  }

  if (["presentationml"].find((item) => fileObject.type.includes(item))) {
    return "./svg/ppt.svg";
  }

  return "./svg/unknown.svg";
};

export const addColor = (type) => {
  const colors = {
    pdf: "#D81B231F",
    excel: "#01723A1F",
    sheet: "#01723A1F",
    csv: "#01723A1F",
    word: "rgb(196, 225, 255)",
    zip: "#fdcc77",
    archive: "#fdcc77",
    powerpoint: "rgb(255, 186, 171)",
    presentation: "rgb(255, 186, 171)",
  };

  const find = Object.keys(colors).find((itm) => type.includes(itm));

  const style = { backgroundColor: colors[find] || "#f1f1f1" };

  if (["sheet", "excel", "csv", "zip", "archive"].includes(find)) {
    style.padding = "7px";
  }

  if (type.includes("image")) {
    style.padding = 0;
    style.overflow = "hidden";
  }

  return style;
};

export const bytesToSize = (bytes, roundMb = false) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  const size = bytes / Math.pow(1024, i);

  if (roundMb && ["MB", "KB"].includes(sizes[i])) {
    return Math.round(size) + " " + sizes[i];
  }

  return Number(size).toFixed(2) + " " + sizes[i];
};

export const sizeMB = (bytes) => {
  if (bytes === 0) return 0;

  return Number(bytes / 1048576).toFixed(2);
};

export const infoAlert = (message) => {
  const alert = `
    <section class="modal open">
      <div class="alert-popap message">
        <div class="modal-header">
          <img onclick={document.querySelector("#infoAlert").parentElement.parentElement.parentElement.remove()} id="infoAlert" src="./svg/close.svg" alt="close modal">
        </div>
        <p style="display: flex; align-self: center; justify-content: center; width: 100%" class="mobile-mg">${message}</p>
      </div>
    </section>
  `;

  document.querySelector("body").insertAdjacentHTML("beforeend", alert);
};

export const downloadUrl = (url, name) => {
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", name);
  a.click();
};
