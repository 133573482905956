const Confirm = ({onCancel, onConfirm, open, title, text, disabled = false}) => open ? 
    (
        <section className={`modal open`}>
            <div className="alert-popap message">
                <div className="modal-header">
                    <img onClick={!disabled ? onCancel : null} src="./svg/close.svg" alt="close modal" />
                    <h2>{title}</h2>
                </div>
                <p className="mobile-mg">{text}</p>
                <div className="group-btn">
                    <button onClick={!disabled ? onCancel : null} className="cancel">No</button>
                    <button onClick={!disabled ? onConfirm : null} className="success">Yes</button>
                </div>
            </div>
        </section>
    ) : null

export default Confirm
