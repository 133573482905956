import React, { useState } from "react";
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";

const Navbar = ({ uploading }) => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false)

    const goToHome = () => {
        if (window.location.pathname === '/upload' && uploading) {
            /* eslint-disable */
            if (confirm('Changes that you made may not be saved.')) {
                navigate('/')
            } else {
                return;
            }
        }

        navigate('/')
    }

    return (
        <>
            <header>
                <a onClick={goToHome}>
                    <img src="./svg/logo.svg" alt="aptos transfer" />
                </a>
                <Link onClick={() => setShow(!show)} to="#" className="works">
                    <span className="icon" style={{ background: 'url(/svg/info.svg) center no-repeat' }} />
                    How it works
                </Link>
            </header>
            <section className={`modal ${show ? '' : 'hide'}`}>
                <div className="alert-popap how">
                    <img onClick={() => setShow(false)} src="./svg/close.svg" alt="close modal" />
                    <h2>How it works ?</h2>
                    <p data-renderer-start-pos="98"><strong data-renderer-mark="true">Aptos Transfer</strong> is an easy way for Aptos employees to send and receive files from anywhere to anywhere in the world.</p><p data-renderer-start-pos="215">If you want to send some files, you have to choose the <strong data-renderer-mark="true">Upload files</strong> feature and from there everything is very straightforward and easy, just choose the files from your computer and click the <strong data-renderer-mark="true">Start uploading</strong> button, we will upload your files to a secure cloud storage and will give you two options to send the link. You can directly send to a recipient using our <strong data-renderer-mark="true">Send via email</strong> function or just <strong data-renderer-mark="true">Copy </strong>the link and send it however you like.</p><ul className="ak-ul" data-indent-level="1"><li><p data-renderer-start-pos="656">You can upload up to 20GB files at the same time;</p></li><li><p data-renderer-start-pos="709">You can choose the expiration date for your link, currently available options are 1 week, 1 month and 3 month;</p></li><li><p data-renderer-start-pos="823">You can choose to add password to your link, that way you get extra protection against unwanted access to your files;</p></li></ul><p data-renderer-start-pos="944">And lastly if you want to receive files from someone, you can <strong data-renderer-mark="true">Generate an empty link</strong> and send this link to the person you want to receive files from. He/she will open the empty link and will be able to upload the files in it, after upload is done, you will receive an email notification and you will be able to download those files immediately.</p><p data-renderer-start-pos="1290">Enjoy transferring!</p>
                </div>
            </section>
        </>
    )
}

export default Navbar
