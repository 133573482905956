import React, { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from "react";
import Api from '../utils/Api'
import { addColor, bytesToSize, downloadUrl, imageSrc, infoAlert } from "../utils/helper";
import moment from "moment";
import { sumBy } from "lodash";
import EnterPassword from "../components/Modals/EnterPassword";
import FileUpload from "../components/Uploads/FileUpload";
import FileList from "../components/Uploads/FileList";
import useUpload from "../hooks/useUpload";
import ProgressBar from "../components/Uploads/ProgressBar";
import Confirm from "../components/Modals/Confirm";
import SettingsMenu from "../components/Settings/SettingsMenu";
import VerifyForm from "../components/VerifyForm";
import useFileSizeLimit from "../hooks/useFileSizeLimit";
import useCookie from "../hooks/useCookie";

const Download = () => {
    const { id } = useParams()
    const [fileItems, setFileItems] = useState(null)
    const [fileLists, setFileLists] = useState([])
    const [loading, setLoading] = useState(false)
    const [needPassword, setNeedPasword] = useState(false)
    const [password, setPassword] = useState(null)
    const [error, setError] = useState(null)
    const [linkName, setLinkName] = useState('Generated link')
    const [isEmpty, setIsEmpty] = useState(false)
    const [showFileUpload, setShowFileUpload] = useState(false)
    const [fileUploading, setFileUploading] = useState(false)
    const [confirmTitle, setConfirmTitle] = useState('')
    const [confirmText, setConfirmText] = useState('Are you sure you want to remove this file ?')
    const [showFileConfirmation, setShowFileConfirmation] = useState(null)
    const [showAbortConfirmation, setShowAbortConfirmation] = useState(false)
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
    const [showSettings, setShowSettings] = useState(false)
    const [showVerification, setShowVerification] = useState(false)
    const [isDownloadOnly, SetIsDownloadOnly] = useState(false)
    const [verificationMethod, setVerificationMethod] = useState(false)
    const [requestVerification, setRequestVerification] = useState(false)
    const { startUpload, progress, setProgress, uploader, isFinalized, setIsFinalized } = useUpload(id)
    const { checkLimit } = useFileSizeLimit()
    const navigate = useNavigate()
    const { setSessionFolder, sessionFolder } = useCookie()

    const fetchData = async (pass) => {
        setLoading(true)
        try {
            const response = await Api.get(`/folder/${id}?password=${pass || ''}`)
            if (response.data.name) {
                setLinkName(response.data.name)
            }
            setFileItems(response.data)
            setLoading(false)
            setNeedPasword(false)
            setIsEmpty(!response.data.files?.length)
            SetIsDownloadOnly(response.data.isDownloadOnly)
        } catch (err) {
            setNeedPasword(err.response.data.hasPassword)
            setError(err.response.data.error)
            setLoading(false)
            
            if (err.response.data.error === 'NOT FOUND') {
                navigate('/not-found')
            }
        }
    }

    const download = async (file = false) => {
        try {
            const url = file ? `/download/${id}?password=${password}&Key=${file}` : `/download/${id}?password=${password}`
            const response = await Api.get(url)

            downloadUrl(response.data, file)
        } catch (err) {
            if (err.response.status === 403) {
                setNeedPasword(true)
            }
        }
    }

    useEffect(() => {
        setPassword(sessionFolder().password || '')
        fetchData(sessionFolder().password);
    }, [])

    useEffect(() => {
        if (isDownloadOnly) {
            setShowFileUpload(false)
        }
    }, [isDownloadOnly])

    useEffect(() => {
        if (isFinalized) {
            setFileLists([])
            setProgress({})
            setFileUploading(false)
            setShowFileUpload(false)

            fetchData(password || sessionFolder().password);
        }
    }, [isFinalized])

    const enterPassword = (password) => {
        fetchData(password)
        setPassword(password)
        setSessionFolder({password})
    }

    const confirmUploaAbort = () => {
        uploader?.abort()
        setFileLists([])
        setFileUploading(false)
        setIsFinalized(false)
        setShowAbortConfirmation(false)
    }

    const onUpload = async (e) => {
        setIsFinalized(false)
        e.preventDefault()

        try {
            const response = await Api.post(`/check/${id}?password=${password || ''}`);
            if (!response.data.success) {
                setIsFinalized(false);
                infoAlert("Try upload latter");
                return;
            }
        } catch (err) {
            setIsFinalized(false);
            infoAlert("Try upload latter");
            return;
        }

        startUpload(fileLists, confirmUploaAbort)
        setFileUploading(true)
    }

    const onRemoveFileFromList = (index, name) => {
        setConfirmTitle(`Remove “${name}”`)
        setShowFileConfirmation(index)
    }

    const handleUpload = (e) => {
        const files = Array.from(e.target.files)
        if (!checkLimit(files, fileItems.files)) {
            return;
        }

        const fileNames = files.map(item => item.name)
        const fileItemsList = fileItems.files.filter(item => fileNames.includes(item.name));

        if (fileItemsList.length) {
            const duplicateFiles = fileItemsList.map(item => item.name).join(', ')
            infoAlert(`This files already exists: ${duplicateFiles}`)
            return;
        }

        setFileLists(Array.from(e.target.files))
        setShowFileUpload(false)
    }

    const addFile = (e) => {
        e.preventDefault()
        if (!checkLimit(fileLists, Array.from(e.target.files))) {
            return;
        }
        setFileLists([...fileLists, ...e.target.files])
    }

    const confirmDelete = async () => {
        try {
            await Api.delete('/remove/' + id)
            setShowDeleteConfirm(false)
            setIsFinalized(true) // trigger reload api
            localStorage.removeItem("a-transfer");
        } catch (err) {
            if (err.response.data.error === 'VERIFICATION INVALID') {
                await sendVerification()
            }
            // infoAlert('Somthing went wrong')
        }
    }

    const confirmFileRemove = () => {
        const newFiles = Array.from(fileLists)
        newFiles.splice(showFileConfirmation, 1)

        setFileLists(newFiles)
        setShowFileConfirmation(null)
    }

    const onShowOptions = (e) => {
        e.preventDefault(); 
        setShowSettings(!showSettings)
    }

    const sendVerification = async (status, callback) => {
        setError('')

        try {
            const response = await Api.post('/resend/' + id, { email: fileItems.email })
            if (response.data.success) {
                setShowVerification(true, callback)
                setVerificationMethod(callback)
            }
        } catch (err) {
            infoAlert(err.response.data.message)
        }
    }

    const successVerify = () => {
        setShowVerification(false)
        setRequestVerification(verificationMethod)
    }

    return (
        <main className={`${showFileUpload || fileLists.length ? 'uploading' : ''} ${fileUploading ? 'progress' : ''} download-page`}>
            <section className="upload-wrapper full">
                <div className="upload-outer">
                    <div className="upload-header">
                        <div className="left-title">
                            <h2>{linkName}</h2>
                            {fileItems && !isEmpty ? <h3>Expire date: {moment(fileItems.expiredAt).format('MMM Do YYYY')}</h3> : ''}
                        </div>
                        <div className="right-title mobile-hide" style={isDownloadOnly ? {width: '26rem'} : null}>
                            {
                                fileItems && !isEmpty ? (
                                    <>
                                        <button className={`download-btn ${!fileItems.zipGenerated ? 'disabled' : null}`} onClick={() => download(`${id}.zip`)}>
                                            <img src="./svg/download.svg" alt="" />
                                            Download all files - <span>({bytesToSize(sumBy(fileItems?.files, 'size'))})</span>
                                        </button>
                                    </>
                                ) : null
                            }
                            {fileItems && !isEmpty && !isDownloadOnly ? (
                                <a href="#" onClick={() => (!fileLists.length && !showFileUpload) ? setShowFileUpload(true) : null} className="upload-icon new-files">
                                    <span className="icon" style={{background: 'url(../svg/upload.svg) center no-repeat', left: 0}} />
                                    <span className="text">Upload new files</span>
                                </a>
                            ) : ''}
                            <a href="#" onClick={fileItems || isEmpty ? onShowOptions : null} className="upload-icon settings">
                                <span className="icon" style={{background: 'url(../svg/settings.svg) center no-repeat', left: 0}} />
                                <span className="text">Settings</span>
                            </a>
                        </div>
                    </div>
                    <div 
                        className={`upload-content${fileUploading ? ' progress' : ''}${showFileUpload || fileLists.length ? ' uploading' : ''}`}
                    >
                        {!fileUploading ? (
                            <div className="upload-section">
                                {(isEmpty && !fileLists.length && !fileUploading) || showFileUpload ? <FileUpload handleUpload={handleUpload} hideContinue={true} /> : null}
                                {fileLists.length && !fileUploading ? <FileList onStartUpload={onUpload} onRemove={onRemoveFileFromList} fileList={fileLists} fileItems={fileItems.files} onAddFile={addFile} /> : null}
                            </div>) : null
                        }

                        {fileUploading && !isFinalized ? <ProgressBar onAbort={() => setShowAbortConfirmation(true)} count={fileLists.length} progress={progress} /> : null}

                        {fileItems?.files && fileItems.files.length && (!fileUploading || isFinalized) ? (
                            <div className={`files-wrapper ${showFileUpload || fileLists.length ? 'uploading' : ''}`}>
                                {fileItems.files.map((item, index) => (
                                    <div className="item" key={index}>
                                        <div className={`type-icon`} style={addColor(item.type)}>
                                            <img src={imageSrc(item, item?.thumb)} style={item?.thumb ? {objectFit: 'cover'} : null} alt="File type icon" />
                                        </div>
                                        {/* <img src={imageSrc(item, item?.thumb)} style={item?.thumb ? {objectFit: 'cover'} : null} alt="File type icon" /> */}
                                        <div className="item-info">
                                            <h2 className="no-wrap-text">{item.name}</h2>
                                            <span>
                                                {bytesToSize(item.size)} <i className="circle-small">●</i> {moment(item.lastModified).format('D MMM. YY(h:mm)')}
                                            </span>
                                        </div>
                                        <div className="action">
                                            <img src="./svg/download.svg" alt="download files" onClick={() => download(item.name)} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                        {needPassword ? <EnterPassword loading={loading} error={error} setError={setError} settedPassword={enterPassword} onClose={() => !loading ? setNeedPasword(false) : null} /> : null}
                        <div className="right-title desctop-hide">
                            <button className="download-btn" onClick={() => download(`${id}.zip`)}>
                                <img src="./svg/download.svg" alt="" />
                                Download all files - <span>({bytesToSize(sumBy(fileItems?.files, 'size'))})</span>
                            </button>
                            {
                                !isDownloadOnly ? (
                                    <a href="#" onClick={() => (!fileLists.length && !showFileUpload) ? setShowFileUpload(true) : null} className="upload-icon new-files">
                                        <span className="icon" style={{background: 'url(../svg/upload.svg) center no-repeat'}} />
                                        Upload new files
                                    </a>
                                ) : null
                            }
                            <a href="#" onClick={fileItems || isEmpty ? onShowOptions : null} className="upload-icon settings">
                                <svg style={{position: 'relative', left: '-11px'}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.7678 7.23223C11.7441 8.20854 11.7441 9.79145 10.7678 10.7678C9.79145 11.7441 8.20854 11.7441 7.23223 10.7678C6.25592 9.79145 6.25592 8.20854 7.23223 7.23223C8.20854 6.25592 9.79145 6.25592 10.7678 7.23223" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M11.8492 1.12002L12.4322 1.31402C12.9662 1.49202 13.3272 1.99202 13.3272 2.55502V3.39202C13.3272 4.10402 13.8952 4.68502 14.6072 4.70002L15.4452 4.71802C15.9302 4.72802 16.3702 5.00702 16.5872 5.44102L16.8622 5.99102C17.1142 6.49502 17.0152 7.10302 16.6172 7.50102L16.0252 8.09302C15.5222 8.59602 15.5132 9.40902 16.0052 9.92302L16.5852 10.529C16.9212 10.88 17.0352 11.387 16.8812 11.848L16.6872 12.431C16.5092 12.965 16.0092 13.326 15.4462 13.326H14.6092C13.8972 13.326 13.3162 13.894 13.3012 14.606L13.2832 15.444C13.2732 15.929 12.9942 16.369 12.5602 16.586L12.0102 16.861C11.5062 17.113 10.8982 17.014 10.5002 16.616L9.90815 16.024C9.40515 15.521 8.59215 15.512 8.07815 16.004L7.47215 16.584C7.12115 16.92 6.61415 17.034 6.15315 16.88L5.57015 16.686C5.03615 16.508 4.67515 16.008 4.67515 15.445V14.608C4.67515 13.896 4.10715 13.315 3.39515 13.3L2.55715 13.282C2.07215 13.272 1.63215 12.993 1.41515 12.559L1.14015 12.009C0.888152 11.505 0.987152 10.897 1.38515 10.499L1.97715 9.90702C2.48015 9.40402 2.48915 8.59102 1.99715 8.07702L1.41715 7.47102C1.08015 7.11902 0.966152 6.61102 1.12015 6.15102L1.31415 5.56802C1.49215 5.03402 1.99215 4.67302 2.55515 4.67302H3.39215C4.10415 4.67302 4.68515 4.10502 4.70015 3.39302L4.71815 2.55502C4.73015 2.07002 5.00815 1.63002 5.44215 1.41302L5.99215 1.13802C6.49615 0.886016 7.10415 0.985016 7.50215 1.38302L8.09415 1.97502C8.59715 2.47802 9.41015 2.48702 9.92415 1.99502L10.5302 1.41502C10.8812 1.08002 11.3892 0.966016 11.8492 1.12002V1.12002Z" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {showVerification ? (
                <section className={`modal`}>
                    <div className="alert-popap verify-download message">
                        <div className="modal-header">
                            <img onClick={() => setShowVerification(false)} src="./svg/close.svg" alt="close modal" />
                            <h2>Verification</h2>
                        </div>
                        <p className="mobile-mg">We send you code email ({fileItems.email}), please enter verification code</p>

                        <VerifyForm email={fileItems.email} folderId={id} verification={true} onSuccess={successVerify} />
                    </div>
                </section>
            ) : null}

            {/* Upload confirm */}
            <Confirm
                open={showFileConfirmation !== null}
                title={confirmTitle}
                text="Are you sure you want to remove this file ?"
                onCancel={() => setShowFileConfirmation(null)}
                onConfirm={confirmFileRemove}
            />
            {/* Abort Upload */}
            <Confirm
                open={showAbortConfirmation}
                title="Stop uploading"
                text="Are you sure you want to stop uploading files?"
                onCancel={() => setShowAbortConfirmation(false)}
                onConfirm={confirmUploaAbort}
            />
            {/* Delete folder confirm */}
            <Confirm
                open={showDeleteConfirm}
                title={confirmTitle}
                text={confirmText}
                onCancel={() => setShowDeleteConfirm(false)}
                onConfirm={confirmDelete}
            />

            {fileItems ? <SettingsMenu 
                onHasPassword={(pass) => setPassword(pass)}
                requestVerification={requestVerification} 
                hasPassword={password} 
                download={true} 
                show={showSettings} 
                setShow={setShowSettings} 
                folderId={id}
                onVerification={sendVerification}
                downloaDonlyMode={fileItems.isDownloadOnly}
                SetIsDownloadOnly={st => SetIsDownloadOnly(st)}
            /> : null }
            {/* set password */}
        </main>
    )
}

export default Download
