import { useEffect, useState } from "react";
import Api from "../utils/Api";
import { infoAlert } from "../utils/helper";
import useCookie from "./useCookie";

const useUpdateFolder = (id, setSuccessMsg) => {
  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [folderId, setFolderId] = useState(null);
  const { sessionFolder } = useCookie();

  useEffect(() => {
    const folderEixsts = sessionFolder();
    setFolderId(folderEixsts?.folder_id || id);
  }, [id]);

  const changeEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    setErrors((errors) => {
      delete errors.sent_email;

      return errors;
    });
  };

  const sendToEmail = async (e) => {
    e.preventDefault();
    setDisabled(true);
    try {
      const data = {
        sent_email: email,
      };

      const response = await Api.post("/update/" + folderId, data);

      if (response.data.success) {
        if (data.sent_email) {
          setSuccessMsg("Email was send successful");
        }

        setEmail("");
      } else {
        infoAlert("Somthing went wrong, try again");
      }
      setDisabled(true);
    } catch (err) {
      setDisabled(false);
      const errors = err.response?.data?.message;
      if (errors) {
        setErrors(errors);
        return;
      }
    }
  };

  return {
    sendToEmail,
    changeEmail,
    errors,
    disabled,
    email,
  };
};

export default useUpdateFolder;
