import React from "react";
import { addColor, bytesToSize, imageSrc, infoAlert } from "../../utils/helper";
import moment from 'moment'

const FileList = ({ fileList, onRemove, onAddFile, onStartUpload, fileItems = [] }) => {
    const fileNames = fileItems.map(item => item.name)
    const fileListItems = Array.from(fileList);
    const fileListItemNames = fileListItems.map(item => item.name)

    const addNewFile = (e) => {
        const files = Array.from(e.target.files)
        const existingFiles = files.filter(item => [...fileListItemNames, ...fileNames].includes(item.name))

        if (existingFiles.length) {
            infoAlert('This files already exists: ' + existingFiles.map(it => it.name).join(', '))
            return
        }

        onAddFile(e)
    }

    return (
        <form method="post" className="files">
            <article className="group-form">
                <div className="files-group">
                    {fileListItems.length ?  fileListItems.map((item, index) => (
                        <div key={index} className="file-item">
                            <div className={`type-icon`} style={addColor(item.type)}>
                                <img src={imageSrc(item)} alt="File type icon" style={imageSrc(item).includes('blob') ? {objectFit: 'cover'} : null} />
                            </div>
                            <div className="files-info">
                                <h2 className="no-wrap-text">{item.name}</h2>
                                <span>{bytesToSize(item.size)}<i className="circle-small">&#9679;</i> {moment(item.lastModified).format('D MMM. YY(h:mm)')}</span>
                            </div>
                            <div className="stop-upload">
                                <img onClick={() => onRemove(index, item.name)} src="./svg/close.svg" alt="stop upload" />
                            </div>
                        </div>
                    )) : null}
                </div>
            </article>
            {/* className="disabled" */}
            <div>
                <label style={{cursor: 'pointer'}}>
                    <span className="icon" style={{background: 'url(../svg/upload.svg) center no-repeat'}} />
                    Add new files
                    <input onChange={addNewFile} type="file" multiple />
                </label>
                <button onClick={onStartUpload}>Start uploading</button> 
            </div>
        </form> 
    )
}

export default FileList
